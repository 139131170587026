import React from 'react';
import PropTypes from 'prop-types';
import SelectSingleFilter from '../SelectSingleFilter/SelectSingleFilter';
import { injectIntl, intlShape } from '../../../util/reactIntl';

const DailyHourlyFilter = props => {
    const {
        id,
        name,
        queryParamNames,
        label,
        options,
        initialValues,
        onSelect,
        intl,
        ...rest
    } = props;

    const queryParamName = queryParamNames[0];

    // Determine the selected option based on the current query parameter values
    const initialQueryParamValue = initialValues ? initialValues[queryParamName] : null;

     // Map selected option to array of priceType values
    let selectedOption = null;


    if (Array.isArray(initialQueryParamValue)) {
        if (initialQueryParamValue.includes('daily')) {
            selectedOption = 'daily';
        } 
        if (initialQueryParamValue.includes('hourly')) {
            selectedOption = 'hourly';
        }
    }

    const filterInitialValues = { [queryParamName]: selectedOption };

    // Define the handleSelect function
    const handleSelect = selectedOption => {
        let value = null;

        if (selectedOption === 'daily') {
            value = ['daily'];
        } else if (selectedOption === 'hourly') {
            value = ['hourly'];
        } else {
            // If the option is cleared
            value = null;
        }

        // Update the query parameters using onSelect
        onSelect({ [queryParamName]: value });
    };


    return (
        <SelectSingleFilter
            id={id}
            name={name}
            label={label}
            options={options}
            initialValues={filterInitialValues}
            onSelect={handleSelect}
            queryParamNames={queryParamNames}
            intl={intl}
            {...rest}
        />
    )
};

DailyHourlyFilter.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    queryParamNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    initialValues: PropTypes.object,
    onSelect: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
};

export default injectIntl(DailyHourlyFilter);